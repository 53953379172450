.cash-flow-model-table {
    border-collapse: collapse;
    width: 100%;
    font-family: Consolas, monospace; /* Use a monospaced font */
  }
  
  .cash-flow-model-table th,
  .cash-flow-model-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }
  
  .cash-flow-model-table th {
    background-color: #f2f2f2;
  }
  
  .cash-flow-model-table input {
    max-width: 125px;
    text-align: center;
    font-family: Consolas, monospace;
  }
  
  .cash-flow-model-table td:first-child {
    font-weight: bold;
    background-color: #f2f2f2;
    text-align: left; /* Align left for better visual hierarchy */
    padding-left: 16px; /* Add left padding for better spacing */
  }
  
  
  .cash-flow-model-table tr:nth-child(5), 
  .cash-flow-model-table tr:nth-child(9), 
  .cash-flow-model-table tr:nth-child(12),
  .cash-flow-model-table tr:nth-child(19){
    background-color: #f9f9f9; /* Use a light background color for better contrast */
    font-weight: bold;
    font-style: italic;
  }
  
  .cash-flow-model-table tr:nth-child(21),
  .cash-flow-model-table tr:nth-child(22) {
    background-color: #e8e8e8;
    font-weight: bold;
    font-style: italic;
  }
  
  .cash-flow-model-table tr:nth-child(6) td,
  .cash-flow-model-table tr:nth-child(10) td,
  .cash-flow-model-table tr:nth-child(13) td,
  .cash-flow-model-table tr:nth-child(20) td {
    background-color: #FFFFFF;
    border: none;
  }
  
  .cash-flow-model-table .positive {
    color: green; /* Use green for positive numbers */
  }
  
  .cash-flow-model-table .negative {
    color: red; /* Use red for negative numbers */
  }
  
  .cash-flow-model-table .icon {
    font-weight: bold;
  }
  
  .cash-flow-model-table .icon-dollar:before {
    content: '$'; /* Use a dollar sign icon */
  }
  
  .cash-flow-model-table .icon-percent:before {
    content: '%'; /* Use a percentage symbol icon */
  }
  
  .cash-flow-model-table .icon-up:before {
    content: '\25B2'; /* Use an upward-pointing arrow icon */
  }
  
  .cash-flow-model-table .icon-down:before {
    content: '\25BC'; /* Use a downward-pointing arrow icon */
  }
  
  .input-header {
    font-weight: normal;
    min-width: 100px;
  }
  

  