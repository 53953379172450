.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.list-ctn{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  width: 100%;
}

.table-container {
  overflow-x: auto;
  width: 100%;
}

.list-ctnComps {
  font-size: 14px;
  color: #000;
  overflow-x: auto;
  background-color: #fff;
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
}


.list-ctnComps th,
.list-ctnComps td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.list-ctnComps th {
  background-color: #f2f2f2;
}

.list{
  min-width: 100px;
  border-right: 1px solid #ddd;
}

.listFinal{
  min-width: 100px;
}

.address{
  min-height: 80px;
}

.card{
  min-width: 100px;
  margin-bottom: 0px;
  border-radius: 10px 10px 10px 10px;
  background-color: #fff;
}
.card-body{
  padding-bottom: 8px;
}

.img-g{
  height: 140px;;
}
.logo img{
  width: 100px;
  margin-top: 20px;

}
body{
  background-color: #001321;
  width: 100%;
}
.btn-size{
  background-color: teal;
    color: #fff;
    padding: 5px 2em;    
    border-radius: 5px;
    font-size: 1.2em;
    margin: 20px auto;
    margin-top: 0;
}
.btn-size:hover{
  background-color: rgb(0, 107, 107);
}
input#txt-address {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  padding: 4px 10px;
  margin-top: 50px;
  /* Remove the dropdown arrow from the input field */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto;
  cursor: pointer;
}
.bl{
  color: #000;
}
.bl .lb{
  width: 40%;
  float: left;
  font-weight: bold;
}
.bl .txt{
  width: 55%;
  float: right;
  text-align: left;
  
}
.card-title{
  text-align: left;
  margin-bottom: 10px;
}
.descr{
  text-align: left;
  
}
.descr h4{
  font-weight: bold;
  margin-bottom: 10px;
  padding-top: 0px;
}
.descr p{
  font-size: 16px;
}
.css-yt9ioa-option {
  color: #000;
}
.css-1n7v3ny-option{
  color: #000;
}
#react-select-2-option-0,
#react-select-2-option-1,
#react-select-2-option-2,
#react-select-2-option-3,
#react-select-2-option-4,
#react-select-2-option-5,
#react-select-2-option-6{
  color :#000 !important;
  font-size: 18px;
}
.ctn-gtxt{
  max-width: 800px;
  width: 100%;
  margin: auto;
  margin-top: 50px;
}
.litem {
  margin: 0;
  padding: 5px;
  text-align: right;
  border-bottom: 1px solid #ddd;
}
.mttt{
  /*margin-top: -13px;*/
  min-width: 100px;
  font-weight: bold;
}
.mk{
  padding: 5px;
  width: 100%;
  background: teal;
}
.litem.ct{
  text-align: center;
}
.valuation{
  background-color: #fff;
  text-align: center;
  color: #000;
}
.valuation h4{
  background: teal;
  color: #fff;
  padding: 5px;
  margin: 0;
}
.valuation p{
  padding: 5px;
    font-size: 18px;
    font-weight: bold;
}
@keyframes ellipsis {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".";
  }
}
.ellipsis::after {
  display: inline-block;
  content: ".";
  animation: ellipsis 1s infinite;
}

.rrd{

}
.litem.rrd {
  height: 54px;
}
.litem{
  height: auto;
}
.litem.rr1 {
  height: 50px;
}
.litem.address {
  height: 115px;
}

.property-photos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px; /* Add 5px gap between images */
  width: 100%; /* Set the width of the image row to 100% of its container */
  overflow-x: auto; /* Enable horizontal scrolling if images don't fit the container width */
}

.property-image {
  flex: 1; /* Distribute the images evenly */
  max-width: calc(100% / 3 - 5px); /* Calculate the maximum width of the images, considering the gap */
  max-height: 158.25px;
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover; /* Fill the space while maintaining the aspect ratio */
}


.table {
  table-layout: fixed;
  padding-bottom: 0;
  margin-bottom: 0;
  width: 100%;
}

.table th {
width: auto;
text-align: right;
}

.table td {
  width: auto;
  text-align: center;
}

.table-responsive {
  overflow-x: auto;
}


.cashFlow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.print-header {
  display: none;
}

@media (max-width: 992px) {

  .image-row {
    flex-wrap: wrap;
  }

  .property-image {
    max-width: calc(100% / 3 - 5px); /* Calculate the maximum width of the images, considering the gap */
    max-height: 158.25px;
    height: auto; /* Maintain the aspect ratio */
  }
}

@media print {
  /* Remove margin and padding from the body */
  body {
    margin: 0;
    padding: 0;
  }

  /* Avoid page breaks inside elements with class 'no-break' */
  .no-break {
    page-break-inside: avoid;
  }

  /* Add a page break before elements with class 'break-before' */
  .break-before {
    page-break-before: always;
  }

  /* Add a page break after elements with class 'break-after' */
  .break-after {
    page-break-after: always;
  }

  .no-print {
    display: none !important;
    height: 0;
    overflow: hidden;
  }
  .property-photos-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  }
  .property-image {
    max-width: calc(100% / 3 - 5px); /* Calculate the maximum width of the images, considering the gap */
    max-height: 158.25px;
    height: auto; /* Maintain the aspect ratio */
  }
  .image-row {
    flex-wrap: wrap;
  }

  .page1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

    /* Adjust the column layout for print view */
    .col-lg-6 {
      width: 50%;
    }
    .cash-flow-print {
      zoom: 50%;
      padding-top: 160px;
    }
    .page1-print {
      zoom: 75%;
    }

    body {
      background-color: #ffffff; /* White background */
      color: #000000; /* Black text color */
    }
    .print-black-text {
      color: #000000; /* Black text color */
      padding-top: 60px;
    }
    .h4-custom-bg {
      background-color: #001321;
      font-weight: bold;
      color: #ffffff;
      text-indent: 2px;
    }
  
    .print-header {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 10px;
      margin-top: 20px;
      color: #000000 !important; /* Black text color */
      font-size: 20px;
    }
  }